<script lang="ts" setup>
const { t } = useT();
const prepareImgUrl = useImage();
const { lightCashData, selectedItem, selectedPackageIndex, badgeFullName, handleOpenCashClick } = useQuickCash();

const bgImage = `url(${prepareImgUrl("/nuxt-img/home/bg-cash.jpg", { format: "avif" })})`;
const bgArt = `url(${prepareImgUrl("/nuxt-img/home/box-cash.png", { format: "avif" })})`;
const bgArtMob = `url(${prepareImgUrl("/nuxt-img/home/box-cash-mob.png", { format: "avif", width: 720, height: 986 })})`;
</script>
<template>
	<section class="quick-cash-wrapper">
		<div class="col-title flex-center">
			<AText class="title" :size="{ full: 40, lg: 22 }" :modifiers="['bold', 'uppercase']" as="div">
				<i18n-t keypath="Select your {key}">
					<template #key>
						<span class="color-tertiary-80">{{ t("Best coin bundle") }}</span>
					</template>
				</i18n-t>
			</AText>
		</div>
		<div v-if="lightCashData" class="col-quick-cash flex-center">
			<div class="quick-cash">
				<div class="content">
					<div class="packages">
						<div
							v-for="(item, index) in lightCashData.packages"
							:key="`${index}-${item}`"
							:class="[
								'package block-styles',
								{ active: selectedPackageIndex === index },
								{ offer: selectedPackageIndex === index && item.promoOfferPreset }
							]"
							@click="selectedPackageIndex = index"
						>
							<AText class="money-sup color-neutral-100" :size="16" :modifiers="['bold', 'center']">
								<span v-html="numberFormatSup(Number(item.money))" />
							</AText>
							<ABadge v-if="item.promoOfferPreset" class="badge" background="var(--additional-a-1)" autosize>
								<AText class="color-neutral-100" :size="8" :modifiers="['bold', 'uppercase']">
									{{ item.promoOfferPreset.bestDeal ? t("Best deal") : t("most popular") }}
								</AText>
							</ABadge>
						</div>
					</div>
					<div :class="['package-reward block-styles active', { offer: selectedItem.promoOfferPreset }]">
						<ABadge v-if="selectedItem.promoOfferPreset" class="badge" background="var(--additional-a-1)" autosize>
							<AText class="color-neutral-100" :size="8" :modifiers="['bold', 'uppercase']">
								{{ badgeFullName }}
							</AText>
						</ABadge>
						<div class="prize-blocks">
							<MPrizeFund variant="coins" icon="20/coins" :icon-size="24">
								<AText class="color-neutral-100" :size="24" :modifiers="['bold']">
									{{
										numberFormat(
											(selectedItem.promoOfferPreset
												? selectedItem.promoOfferPreset.coins
												: selectedItem.preset?.coins) || 0
										)
									}}
								</AText>
							</MPrizeFund>
							<MPrizeFund class="margin-entries" variant="entries" icon="20/entries" :icon-size="20">
								<AText :size="18" :modifiers="['bold']">
									{{
										numberFormat(
											(selectedItem.promoOfferPreset
												? selectedItem.promoOfferPreset.entries
												: selectedItem.preset?.entries) || 0
										)
									}}
									<AText :size="10" :modifiers="['bold', 'uppercase']"> {{ t("for free") }} </AText>
								</AText>
							</MPrizeFund>
						</div>
						<AText class="money-sup color-additional-a-3" :size="{ full: 28, md: 24 }" :modifiers="['bold']" as="div">
							<span v-html="numberFormatSup(Number(selectedItem.money))" />
						</AText>
					</div>
				</div>
				<div class="content bottom">
					<AButton variant="primary" size="xl" :modifiers="['wide']" @click="handleOpenCashClick">
						<AText :size="16" :modifiers="['bold', 'uppercase']">
							<i18n-t keypath="Buy now for ${key}">
								<template #key>
									{{ numberFormat(selectedItem.money, { minimumFractionDigits: 2 }) }}
								</template>
							</i18n-t>
						</AText>
					</AButton>
					<NuxtImg
						src="/nuxt-img/home/logos2x.png"
						format="avif"
						alt="pay_logos"
						width="290"
						height="30"
						loading="lazy"
						class="logos"
					/>
					<div class="secure">
						<NuxtIcon name="16/secure" filled />
						<AText class="color-success-60" :size="12" :modifiers="['bold']">
							{{ t("Secure online payment") }}
						</AText>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<style lang="scss" scoped>
.money-sup :deep(sup) {
	font-size: 62%;
	top: -0.5em;
	left: 0.1em;
}

.quick-cash-wrapper {
	display: flex;
	justify-content: center;
	min-height: 453px;
	height: 100%;
	background: var(--custom-9) v-bind(bgImage) no-repeat center right / cover;
	gap: 16px;
	position: relative;

	@include media-breakpoint-up(md) {
		justify-content: flex-end;
		border-radius: 12px;
		margin: 16px 0;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		padding: 16px 0;
		background-position: 88%;

		> * {
			position: relative;
			z-index: 1;
		}

		&:after,
		&:before {
			content: "";
			display: flex;
			width: 100%;
			height: 62px;
			opacity: 0.6;
			background: linear-gradient(
				180deg,
				rgba(11, 16, 32, 0) 0%,
				rgba(11, 16, 32, 0.39) 44.97%,
				rgba(11, 16, 32, 0.39) 64.93%,
				#0b1020 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:after {
			top: 0;
			bottom: auto;
			transform: rotate(180deg);
		}
	}

	.title {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 32px;

		@include media-breakpoint-down(md) {
			margin-bottom: -60px;
			align-items: center;
		}
	}
}

.col-quick-cash {
	width: 100%;
	background: v-bind(bgArtMob) no-repeat center -22px / 360px 493px;

	@include media-breakpoint-up(md) {
		width: 800px;
		background: v-bind(bgArt) no-repeat center / contain;
	}

	@include media-breakpoint-down(md) {
		padding: 60px 0 50px;
		margin-bottom: -40px;
	}
}

.quick-cash {
	width: 328px;
	max-width: 100%;
	border-radius: 12px;
	background: var(--neutral-15);
	overflow: hidden;

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		padding: 16px;

		&.bottom {
			background: var(--neutral-20);
			gap: 12px;
			padding: 16px;
		}

		.block-styles {
			border-radius: 8px;
			background-color: var(--neutral-15);
			border: 1px solid var(--secondary-50);
			padding: 12px 7px;
			transition: all 200ms ease-in-out;

			&.active {
				border-color: var(--additional-a-1);
				background-color: var(--secondary-15);
			}

			&.offer {
				border-color: var(--additional-a-1);
			}
		}
	}

	.packages {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 16px 7px;
		margin-top: 8px;

		.package {
			position: relative;
			text-align: center;
			cursor: pointer;

			&:hover:not(.active):not(.offer) {
				background-color: var(--secondary-15);
			}

			.badge {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				padding: 2px 8px;
				border-radius: 17px;
				white-space: nowrap;
			}
		}
	}

	.package-reward {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 14px;

		&.block-styles {
			padding: 6px 16px;
		}

		.badge {
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 0 7px 0 10px;
			padding: 2px 6px;
			z-index: 2;
		}

		.prize-blocks {
			display: flex;
			flex-direction: column;
			gap: 2px;
		}

		.margin-entries :deep(.nuxt-icon) {
			margin: 0 2px 0 2px;
		}
	}
}
.logos {
	width: 144px;
	height: 15px;
}
</style>
